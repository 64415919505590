<template>
  <div class="view" :class="`view--cryptocurrencies view--cryptocurrencies-${key}`">
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-7 col-xl-8 mb-5 mb-lg-0">
            <p class="txt fw-400 fc-green ts-20 ts-md-22 mb-3" v-html="cc.s1.tit[0]" />
            <h1 class="tit fw-500 fc-dark ts-36 ts-md-48 mb-4" v-html="cc.s1.tit[1]" />
            <p class="txt fw-400 fc-gray pe-5" v-html="cc.s1.txt" />
          </div>
          <div class="col-12 col-lg-5 col-xl-4 px-0 px-md-2">
            <comp-crypto comp="about" :asset="key" />
          </div>
        </div>
      </div>
    </section>
    <section class="section section--dark section--dark-btc">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-8">
            <p class="txt fw-400 fc-green ts-20 ts-md-22 mb-3" v-html="cc.s2.tit[0]" />
            <h2 class="tit fw-500 fc-white ts-26 ts-md-32 mb-4" v-html="cc.s2.tit[1]" />
            <p class="txt fc-silver ts-14 mb-4" v-for="(item, i) in cc.s2.txt" :key="`about-crypto-txt-${i}`" v-html="item" />
          </div>
          <div class="col-12 col-lg-4">
            <div class="img img--crypto-about">
              <img :src="`/img/components/crypto/about/img-${key}.png`" :alt="key">
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <comp-start />
    </section>
  </div>
</template>
<script>
import CompCrypto from '@/components/Crypto'
import CompStart from '@/components/Start'
export default {
  components: {
    CompCrypto, CompStart
  },
  data: () => ({
    keys: {
      bitcoin: 'btc',
      ethereum: 'eth',
      cardano: 'ada',
      decentraland: 'mana'
    }
  }),
  computed: {
    key () {
      return this.keys[this.$route.params.key]
    },
    meta () {
      return this.$t('meta.cryptocurrencies')[this.key]
    },
    cc () {
      return this.$t('views.cryptocurrencies')[this.key]
    }
  },
  metaInfo () {
    const meta = this.meta
    return {
      titleTemplate: '%s' + (meta.title ? ' | ' + meta.title : ''),
      meta: [
        { name: 'description', content: meta.description },
        { name: 'keywords', content: meta.keywords }
      ]
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>
